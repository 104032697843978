import styled from "styled-components";

export const SubscribeWrapper = styled.div`
  margin: 5rem 12.5rem 5rem 12.5rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 25px;
  opacity: 1;
  border-color: #e2e8f0;
  background-color: #fcfcfc;
  height: 14rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SubscribeTitle = styled.h1`
  max-width: 30ch;
  margin-left: 8rem;
  font-weight: 700;
  line-height: 1.4em;
  font-size: 23px;
  font-family: sans-serif;
`;

export const SubscribeInputStyle = styled.input`
  background-color: rgb(235, 235, 235);
  color: black;
  border-radius: 8px;
  padding: 15px 15px 15px 15px;
  height: 22px;
  border-style: none;
  margin-left: 10rem;
  width: 250px;
`;
