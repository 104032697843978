import BlogCard from "./BlogCard";
import { BlogContainerStyle } from "../styles/BlogContainerStyle";
import Blog1 from "../assets/Blog1.webp";
import Blog2 from "../assets/Blog2.webp";
import Blog3 from "../assets/Blog3.webp";

const BlogContainer = () => {
  return (
    <BlogContainerStyle>
      <BlogCard
        blogImage={Blog1}
        title={"How To Create an Animated Loading Spinner with Tailwind CSS"}
        publishedDate={"Jul 19, 2023"}
      />
      <BlogCard
        blogImage={Blog2}
        title={"The Role of CSS in Modern Web Development"}
        publishedDate={"Jul 13, 2023"}
      />
      <BlogCard
        blogImage={Blog3}
        title={
          "Boosting Developer Productivity with a Second Brain: The Power of Obsidian"
        }
        publishedDate={"May 15, 2023"}
      />
    </BlogContainerStyle>
  );
};

export default BlogContainer;
