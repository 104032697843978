import {
  BlogCardStyle,
  ImageWrapper,
  BlogCardImage,
  BlogTitle,
  BlogDate,
  BlogPreview,
} from "../styles/BlogCardStyle";

const BlogCard = (props) => {
  const { blogImage, title, publishedDate } = props;
  return (
    <BlogCardStyle className="blog-card">
      <ImageWrapper className="blog-card-img-wr">
        <BlogCardImage className="blog-card-img" src={blogImage} />
      </ImageWrapper>
      <BlogPreview>
        <BlogTitle className="blog-card-title">{title}</BlogTitle>
        <BlogDate className="blog-card-date">{publishedDate}</BlogDate>
      </BlogPreview>
    </BlogCardStyle>
  );
};

export default BlogCard;
