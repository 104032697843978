import styled from "styled-components";

export const BlogWrapper = styled.div`
  margin-left: 10rem;
  margin-right: 10rem;
`;

export const BlogHeading = styled.h1`
  margin-left: 2.5rem;
  font-family: sans-serif;
`;

export const BlogText = styled.h3`
  margin-left: 2.5rem;
  font-weight: 400;
  color: rgb(51, 65, 85);
  line-height: 1.2em;
  font-size: 25px;
`;
