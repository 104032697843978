import styled from "styled-components";
import backgroundImage from "../assets/Intro_background.webp";

export const IntroWrapper = styled.div`
  background-color: white;
  background-image: url(${backgroundImage});
  background-size: contain;

  margin-top: 6rem;
  margin-left: 10rem;
  margin-right: 10rem;
  margin-bottom: 7rem;
`;

export const ContentWrapper = styled.div`
  height: 285px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
`;

export const IntroContent = styled.h1`
  padding-top: 3rem;
  max-width: 35ch;
  font-weight: 800;
  font-family: "Manrope", sans-serif;
  font-size: 48px;
  letter-spacing: -1.5px;
  line-height: 1.2em;
  text-align: start;
  margin-left: 2.5rem;
`;

export const IntroImageWrapper = styled.div`
  margin-left: 5rem;
  padding-left: 2rem;
`;

export const IntroImage = styled.img`
  height: 160px;
  border-radius: 50%;
  width: 160px;
`;

export const IntroSpan = styled.span`
  font-weight: 800;
  letter-spacing: 0;
  line-height: 1.2em;
  text-align: start;
  color: rgb(21, 184, 166);
`;

export const ButtonSection = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-left: 2.5rem;
`;
