import Header from "./Header";
import Intro from "./Intro";
import Blog from "./Blog";
import Subscribe from "./Subscribe";
import Footer from "./Footer";

const Landing = () => {
  return (
    <div>
      <Header />
      <Intro />
      <Blog />
      <Subscribe />
      <Footer />
    </div>
  );
};

export default Landing;
