import styled from "styled-components";

export const SecondaryButtonStyle = styled.button`
  border-color: "#E2E8F0";
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  width: 110px;
  height: 50px;
  cursor: pointer;
  background-color: rgb(99, 102, 241);
  margin-left: 1rem;
`;

export const SecondaryButtonH3 = styled.h3`
  color: white;
  font-weight: 400;
  font-size: 16px;
`;
