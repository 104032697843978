import styled from "styled-components";

export const HeaderWrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 5rem;
  position: fixed;
  top: 0;
`;

export const UnorderedListWrapper = styled.div`
  display: flex;
  list-style-type: none;
  justify-content: center;
`;

export const ListItem = styled.div`
  margin: 1.5rem 1rem;
  padding: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: "#707b89";
    opacity: 0.5;
  }
  &:nth-child(3) {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
`;

export const ImageWrapper = styled.div``;

export const LogoWrapper = styled.img``;
