import { BlogWrapper, BlogHeading, BlogText } from "../styles/BlogStyle";
import BlogContainer from "./BlogContainer";

const Blog = () => {
  return (
    <BlogWrapper className="blog-wr">
      <BlogHeading className="blog-heading">
        I love to share my knowledge through writing
      </BlogHeading>
      <BlogText className="blog-text">
        Check out a few of my most recent publishings.
      </BlogText>
      <BlogContainer></BlogContainer>
    </BlogWrapper>
  );
};

export default Blog;
