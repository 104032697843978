import {
  HeaderWrapper,
  UnorderedListWrapper,
  ListItem,
  ImageWrapper,
  LogoWrapper,
} from "../styles/HeaderStyle";

import Logo from "../assets/logo.svg";

const Header = () => {
  return (
    <HeaderWrapper className="header-wr">
      <UnorderedListWrapper className="ul-headeer">
        <ListItem>Home</ListItem>
        <ListItem>About</ListItem>
        <ListItem>
          <ImageWrapper>
            <LogoWrapper src={Logo}></LogoWrapper>
          </ImageWrapper>
        </ListItem>
        <ListItem>Projects</ListItem>
        <ListItem>Blog</ListItem>
      </UnorderedListWrapper>
    </HeaderWrapper>
  );
};

export default Header;
