import {
  CopyrightWrapper,
  FooterWrapper,
  SocialWrapper,
  SocialMediaImg,
  CopyrightContent,
} from "../styles/FooterStyle";

import GithubIcon from "../assets/github_icon.png";
import LinkedinIcon from "../assets/linkedin_icon.png";

const Footer = () => {
  return (
    <FooterWrapper>
      <SocialWrapper>
        <SocialMediaImg src={GithubIcon} />
        <SocialMediaImg src={LinkedinIcon} />
      </SocialWrapper>
      <CopyrightWrapper>
        <CopyrightContent>
          © 2024 Utkrista KC. All rights reserved.
        </CopyrightContent>
      </CopyrightWrapper>
    </FooterWrapper>
  );
};

export default Footer;
