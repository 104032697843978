import styled from "styled-components";

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 8rem;
`;

export const SocialWrapper = styled.div`
  display: flex;
`;

export const SocialMediaImg = styled.img`
  width: 30px;
  height: 30px;
  padding: 1rem;
`;

export const CopyrightWrapper = styled.div``;

export const CopyrightContent = styled.h3`
  font-weight: 500;
  font-size: 14px;
  color: rgb(75, 85, 99);
  line-height: 1.5em;
`;
