import styled from "styled-components";

export const BlogCardStyle = styled.div``;

export const ImageWrapper = styled.div`
  width: 450px;
  height: 403px;
  max-height: 410px;
  max-width: 352px;
  overflow: hidden;
  border-radius: 20px;
`;

export const BlogCardImage = styled.img`
  width: 450px;
  height: 403px;
  max-height: 410px;
  max-width: 352px;
`;

export const BlogPreview = styled.div`
  gap: 10px;

  max-width: 352px;
`;

export const BlogTitle = styled.h3`
  width: auto;
  min-width: 100%;
  height: auto;
  white-space: pre-wrap;
  font-size: 25px;
`;

export const BlogDate = styled.h3`
  color: rgb(153, 153, 153);
  font-weight: 400;
  font-family: sans-serif;
  line-height: 29px;
`;
