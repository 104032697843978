import styled from "styled-components";

export const ButtonStyle = styled.button`
  background-color: rgb(30, 41, 59);
  align-items: center;
  font-family: "Manrope", sans-serif;
  flex-wrap: nowrap;
  /* height: min-content; */
  justify-content: center;
  padding: 18px 50px;
  /* width: min-content; */
  font-size: 20px;

  border-style: outset;
  margin-right: 5rem;
  font-weight: 550;
  color: white;
  border-radius: 50px;
  cursor: pointer;
`;
