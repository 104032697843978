import SecondaryButton from "../components/SecondaryButton";
import {
  SubscribeWrapper,
  SubscribeInputStyle,
  SubscribeTitle,
} from "../styles/SubscribeStyle";

const Subscribe = () => {
  return (
    <SubscribeWrapper>
      <SubscribeTitle>
        Stay up to date with my articles, projects and findings.
      </SubscribeTitle>
      <SubscribeInputStyle type="text" placeholder="name@email.com" />
      <SecondaryButton />
    </SubscribeWrapper>
  );
};

export default Subscribe;
