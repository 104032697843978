import {
  IntroWrapper,
  ContentWrapper,
  IntroContent,
  IntroImageWrapper,
  IntroImage,
  IntroSpan,
  ButtonSection,
} from "../styles/IntroStyle";

import Image from "../assets/Intro_image.jpg";
import Button from "./Button";
import LinkButton from "./LinkButton";

const Intro = () => {
  return (
    <div>
      <IntroWrapper className="intro-wr">
        <ContentWrapper className="intro-content">
          <IntroContent className="intro-h1">
            I'm <IntroSpan>Utkrista</IntroSpan>. I am a developer, blogger and
            speaker working at People Pool.
          </IntroContent>
          <IntroImageWrapper>
            <IntroImage src={Image}></IntroImage>
          </IntroImageWrapper>
        </ContentWrapper>
        <ButtonSection>
          <Button></Button>
          <LinkButton />
        </ButtonSection>
      </IntroWrapper>
    </div>
  );
};

export default Intro;
