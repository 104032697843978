import {
  SecondaryButtonStyle,
  SecondaryButtonH3,
} from "../styles/SecondaryButtonStyle";

const SecondaryButton = () => {
  return (
    <SecondaryButtonStyle className="subscribe-button">
      <SecondaryButtonH3>Subscribe</SecondaryButtonH3>
    </SecondaryButtonStyle>
  );
};

export default SecondaryButton;
